import { useEffect, useState } from "react";
import { PaymentStep } from "../subscription";
import { KionButton } from "../../button/button";
import { useNavigate } from "react-router-dom";
import { Subscription } from "../../../util/promo";
import { useStoreCtx } from "../../../store";
import {
  DataLayer,
  MtsBankWidgetConfig,
  PaymentSystemEnum,
  PaymentTypeEnum,
  RelatedSubscriptionsTextRequest,
} from "../../../store/appStore";
import s from "./style.module.scss";

declare global {
  interface Window {
    MTSPay: any;
  }
}

export function PaymentConfirm(props: {
  subscription: Subscription;
  paymentMethod: PaymentStep;
  onNext: (val: PaymentStep) => void;
}) {
  const httpService = useStoreCtx().appStore;

  const routerPush = useNavigate();
  const [processingTransaction, setProcessingTransaction] = useState(false);
  const [processText, setProcessText] = useState("");
  const currentPromocode = 'LANDING3';

  const commonDataLayer: DataLayer = {
    event: "subscription_success",
    event_name: "subscription_success",
    referer: "/subscriptions",
    price: props.subscription.discountPrice,
    subscription_name: props.subscription.name,
    subscription_id: props.subscription.productId,
	promocode: currentPromocode,
    deeplink: null,
    request_id: null,
    is_trial: 0,
  };

  function onPreviousStep() {
    props.onNext(PaymentStep.Init);
  }

  function pay() {
    setProcessingTransaction(true);
    if (props.paymentMethod === PaymentStep.Phone) {
      payWithPhone()
        .then((el) => {
          if (el.status === 200) {
            routerPush({
              pathname: "/success",
              search: new URLSearchParams(httpService.getAnalyticsQuery()).toString(),
            });
            httpService.pushEvent({
              ...commonDataLayer,
              payment_type: "vps",
            });
          } else pushError("Request failed with status code" + el.status);
        })
        .catch((e) => {
          window.sessionStorage.removeItem("request_id");
          pushError(e.message);
        });
      return;
    }
    if (props.paymentMethod === PaymentStep.Card) {
      payWithCard();
      return;
    }
  }

  function pushError(msg: string) {
    console.log("pushError", msg);

    httpService.pushEvent({
      event: "subscribe_error",
      event_name: "subscribe_error",
      price: props.subscription.discountPrice,
      subscription_name: props.subscription.name,
      subscription_id: props.subscription.productId,
      promocode: currentPromocode,
      error_message: msg,
      request_id: null,
    });
    routerPush({ pathname: "/error", search: new URLSearchParams(httpService.getAnalyticsQuery()).toString() });
  }

  function payWithPhone() {
    setProcessingTransaction(true);
    return httpService.payWithPhone(props.subscription);
  }

  const [successEventPushed, setSuccessEventPushed] = useState(false);
  async function payWithCard() {
    setProcessingTransaction(true);
    try {
      await injectWidget();

      const cfg = await httpService.getWidgetConfig(
        props.subscription,
        async (res) => {
          // Виджет почему-то вызывает коллбек 2 раза (второй раз с эвентом "close")
          if (res.eventName === "close") return;

          if (!res.isSuccess && res.eventName === "failPayment") {
            pushError("failPayment");
          } else {
            const applyed = await checkSubscription(props.subscription);

            if (applyed) {
              routerPush({
                pathname: "/success",
                search: new URLSearchParams(httpService.getAnalyticsQuery()).toString(),
              });
              if (!successEventPushed) {
                setSuccessEventPushed(true);
                httpService.pushEvent({
                  ...commonDataLayer,
                  payment_type: "mts_money",
                });
              }
            } else {
              pushError("subscription not applyed");
            }
          }
        }
      );

      setupAndRunWidget(cfg);
    } catch (e) {
      pushError("pay with card: something went wrong");
    }
  }

  function injectWidget(fallback = false) {
    return new Promise((resolve, reject) => {
      let widget = document.createElement("script");

      widget.onload = () => {
        console.info("Widget installed");
        resolve("");
      };

      widget.onerror = (err) => {
        if (!fallback) {
          document.head.removeChild(widget);
          console.warn(
            "Widget download failed. Try to install from older version"
          );
          injectWidget(true).then(resolve).catch(reject);
        }
        if (fallback) {
          console.error("Widget installation failed.");
          reject(err);
        }
      };

      document.head.appendChild(widget);
      widget.src = fallback
        ? "/mts-bank-payment-widget.js"
        : "https://pay.mts.ru/assets/js/web-sdk/v1/sdk.js";
    });
  }

  async function checkSubscription(s: Subscription): Promise<boolean> {
    setProcessText("Производится активация подписки...");
    let timeout = 2000;
    for (let i = 0; i < 7; i++) {
      if (i === 0) timeout = 2000;
      if (i === 1) timeout = 3000;
      if (i > 1) timeout = 5000;

      try {
        let res = (await retry(s, timeout)) as any[];

        if (res.length > 0) {
          httpService.subscriptionExist = true;
          break;
        }
      } catch (e) {
        pushError("check subscription: something went wrong when");
      }
    }
    return httpService.subscriptionExist;
  }

  function retry(s: Subscription, timer: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        httpService
          .filterAppliedSubscriptions(s)
          .then((el) => res(el.data))
          .catch(rej);
      }, timer);
    });
  }

  function setupAndRunWidget(config: MtsBankWidgetConfig) {
    if (window.MTSPay) {
      const widget = new window.MTSPay(config);
      widget.render("bank-payment-widget");
      handleCloseButton();
      const shadowRoot = document.querySelector(
        "#bank-payment-widget"
      )?.shadowRoot;
      shadowRoot?.addEventListener("click", () => {
        handleCloseButton();
      });
    }
  }

  function handleCloseButton() {
    const intervalId = setInterval(() => {
      const root = document.querySelector("#bank-payment-widget")?.shadowRoot;

      const closeButton = root?.querySelector(".mtsPayModalHeaderRightButton");
      if (closeButton) {
        closeButton.addEventListener(
          "click",
          () => {
            setProcessingTransaction(false);
            if (root) root.innerHTML = "";
          },
          false
        );
      }

      // ! Виджет может полностью ререндериться
      // Поэтому ждем пока не найдется первый инпут (после этого считаем, что виджет полностью инициализирован и листенеры висят на всех кнопках закрытия)
      const hasInput = !!root?.querySelector("input");
      if (hasInput || root?.children.length === 0) clearInterval(intervalId);
    }, 500);
  }

  const [title, setTitle] = useState<string>("Подключение подписки");
  const [description, setDescription] = useState<string>(
    `Вы пытаетесь подключить пакет «${props.subscription.name}» ${getCostString()}. Продолжить?`
  );
  function getCostString() {
    const price = `${props.subscription.discountPrice / 100}\u00A0₽`;
    let period = "";
    if (props.subscription.promotion.timeToLiveValue === 3)
      period = "за 3 месяца";
    if (props.subscription.promotion.timeToLiveValue === 6)
      period = "за 6 месяцев";
    if (props.subscription.promotion.timeToLiveValue === 12) period = "в год";

    // TODO взять период подписки
    return `за ${price} ${period}`;
  }
  const [payButtonText, setPayButtonText] = useState<string>("Подключить");

  const data: RelatedSubscriptionsTextRequest = {
    productGid: props.subscription.productId,
    promoCode: currentPromocode,
    paymentType: {
      source:
        props.paymentMethod === PaymentStep.Card
          ? PaymentTypeEnum.CARD
          : PaymentTypeEnum.ACCOUNT,
      system:
        props.paymentMethod === PaymentStep.Card
          ? PaymentSystemEnum.MTS_MONEY
          : PaymentSystemEnum.FORIS_ONLINE,
    },
  };

  useEffect(() => {
    httpService.checkSubscription(data).then((res) => {
      setTitle(res.data.header);
      setDescription(res.data.text);
      setPayButtonText(res.data.button);

      //  Если есть блокирующие подписки
      if (
        res.data.blockingProductGids.length ||
        res.data.cancelingProductGids.length
      ) {
        httpService.pushEvent({
          event: "subscribe_error",
          event_name: "subscribe_error",
          price: props.subscription.discountPrice,
          subscription_name: props.subscription.name,
          subscription_id: props.subscription.productId,
          promocode: currentPromocode,
          error_message: `
			User already has blocking product: "${res.data.blockingProductGids.join(", ")}" 
			or cancelling product: "${res.data.cancelingProductGids.join(", ")}"
		  `,
          request_id: null,
        });
      }
    });
  }, []);

  return (
    <>
      {!processingTransaction ? (
        <div className={s["payment-wrapper"]}>
          <div className={s.block}>
            <img src="images/attention-dark.png" />
            <div className={s.text}>
              <h2 className={s.title}>{title}</h2>
              <p className={s.description}>{description}</p>
            </div>

            <div className={s.actions}>
              <KionButton
                negative={true}
                onClick={onPreviousStep}
                confirmVariant
              >
                Отменить
              </KionButton>
              {payButtonText && (
                <KionButton onClick={pay} confirmVariant>
                  {payButtonText}
                </KionButton>
              )}
            </div>
          </div>

          <div className={s.offer}>
            Продолжая покупку вы принимаете условия
            <br />
            <a href="https://kion.ru/user-agreement" target={"_blank"}>
              Пользовательского соглашения
            </a>
            <br />© 2025 МТС МЕДИА. ПАО «МОБИЛЬНЫЕ ТЕЛЕСИСТЕМЫ». 18+
          </div>
        </div>
      ) : (
        <div className={s["processing-wrapper"]}>
          Пожалуйста, подождите <br />
          {processText}
        </div>
      )}
    </>
  );
}
